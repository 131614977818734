import Swiper, {EffectFade, Autoplay} from "swiper";

const bgSlider = document.querySelector('[js-watches-slider]');

if (bgSlider) {
  const sliderImages = document.querySelectorAll('.bg-slider__img');
  Swiper.use([EffectFade, Autoplay]);

  const swiper = new Swiper(bgSlider, {
    effect: 'fade',
    allowTouchMove: false,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    loop: 'true'
  })

  swiper.on('slideChange', () => {
    sliderImages.forEach(image => {
      image.classList.remove('hide')
    })

    sliderImages.forEach((image,index) => {
      if (index !== swiper.realIndex) {
        image.classList.add('hide')
      }
    })
  });
}
