import '@babel/polyfill'

// Site
import './site/site-load'
import './site/site-panel'
import './site/site-nav'
import './site/site-slider'
import './site/site-info'

// Page
import './page/page-home'
import './page/page-story'
import './page/page-info'

// Product
import './product/product-category'

import './slider/bg-slider'

// Popup
import {openPopupByBtn} from "./popup/_openPopup";
import './popup/popup.js'
import './popup/callback.js'

Array.from(document.getElementsByClassName('js-open-popup'))
  .forEach((btnElem) => {
    openPopupByBtn(btnElem)
  })
