(function () {
  const body = document.body
  const panelBtn = document.querySelector('.js-panel-btn')
   const firstLoad = localStorage.getItem('firstLoad')
  const navLinks = document.querySelectorAll('.menu-item')
  const MOVE_CONTENT = 'move-down-content'
  const PANEL_OPEN = 'is-panel-open'

  if (!document.body.classList.contains('home')) {
    if (!firstLoad) {
      body.classList.remove(PANEL_OPEN)
    }

    if (!body.classList.contains(PANEL_OPEN) && !firstLoad) {
      setTimeout(() => body.classList.add(PANEL_OPEN), 2000)
      localStorage.setItem('firstLoad', 'done')
    }

    panelBtn.addEventListener('click', function () {
      body.classList.toggle(PANEL_OPEN)
    })
  } else {
    body.classList.remove(PANEL_OPEN)
    panelBtn.addEventListener('click', function () {
      const baseUrl = window.location.origin
      let collectionsUrl = new URL('/collections', baseUrl)
      window.open(collectionsUrl.href, "_self")
    })
  }


  if (navLinks) {
    const navWidth = () => {
      if (navLinks.length >= 5  && window.innerWidth < 1360) {
        navLinks.forEach((navItem, index) => {
          if (index > 3) {
            document.querySelector('.site-main').classList.add(MOVE_CONTENT)
            document.querySelector('.site-panel').classList.add(MOVE_CONTENT)
          }
        })
      }
    }
    window.addEventListener("resize", navWidth)
  }
})()
