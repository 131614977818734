import Swiper, { Navigation, Mousewheel } from 'swiper';

const eventsSliderEl = document.querySelector('[data-events-slider]');
const sliderTitle = document.querySelector('.site-slider__title');
const sliderSection = document.querySelector('.site-slider');

if (eventsSliderEl) {
  Swiper.use([Navigation, Mousewheel]);

  new Swiper(eventsSliderEl, {
    slidesPerView: 1,
    loop: 'true',
    spaceBetween: 0,
    mousewheel: true,
    navigation: {
      nextEl: '.site-slider__next',
      prevEl: '.site-slider__prev',
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1360: {
        slidesPerView: 4,
        spaceBetween: 30,
      }
    }
  });

  sliderTitle.addEventListener('click', () => {
    sliderSection.classList.toggle('show');
  })
}
