(function () {
  const MENU_OPEN_CLASS = 'site-nav--opened'
  const BODY_MENU_OPEN_CLASS = 'body-opened-menu'
  const ACTIVE_CLASS = 'is-active'

  const body = document.body
  const navMenu = document.querySelector('[data-nav="wrap"]')
  const navBurger = document.querySelector('[data-nav="burger"]')

  if (navBurger && navMenu) {
    navBurger.addEventListener('click', function (e) {
      e.preventDefault()

      navBurger.classList.toggle(ACTIVE_CLASS)
      navMenu.classList.toggle(MENU_OPEN_CLASS)
      body.classList.toggle(BODY_MENU_OPEN_CLASS)
    })
  }
})()
