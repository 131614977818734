// (function () {
//   const loadBlock = document.querySelector('.site-loading')
//   const HIDDEN = 'is-hidden'
//   const FINAL = 'is-final'

//   window.addEventListener('load', function () {
//     setTimeout(() => loadBlock.classList.add(HIDDEN), 750);
//     setTimeout(() => loadBlock.classList.add(FINAL), 2000);
//   })
// })()

if (document.body.classList.contains('home')) {

  const loadBlock = document.querySelector('[data-site-load]');

  window.addEventListener('load', function () {
    setTimeout(() => loadBlock.classList.add('is-hidden'), 750);
    setTimeout(() => loadBlock.classList.add('is-final'), 2000);
  })
}
