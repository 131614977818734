import {openPopupByBtn} from "./_openPopup";

(function () {

  const openPopupBtns = Array.from(document.querySelectorAll('.js-open-popup-question'))

  if (openPopupBtns.length) {
    openPopupBtns.forEach((btnElem) => {
      openPopupByBtn(btnElem)
    })
  }
})()

