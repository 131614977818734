import Swiper, { Mousewheel } from 'swiper';

const storySliderEl = document.querySelector('[data-story-slider]');
if (storySliderEl) {
  const storySliderInit = () => {
    const storySlider = new Swiper(storySliderEl, {
      modules: [Mousewheel],
      mousewheel: true,
      slidesPerView: 2,
      // autoHeight: true,
      direction: 'horizontal',
    });

    window.innerWidth > 768 ? storySlider.init() : storySlider.destroy();
  }

  storySliderInit();

  window.addEventListener("resize", storySliderInit);
}