const siteInfo = document.querySelector('.site-info');

if (siteInfo) {
  const siteTimeBlock = document.querySelector('.site-info__time');
  let div = document.createElement('div');
  div.classList.add('site-info__data');

  function getDate() {
    let date = new Date();
    const monacoTime = date.toLocaleString("fr-MC", {
      timeZone: "Europe/Monaco",
      timeStyle: 'medium'
    });

    div.innerHTML = monacoTime.slice(0, -3);
    siteTimeBlock.appendChild(div);
  }

  setInterval(getDate, 1000)
}
