const pageInfo = document.querySelector('.page-info');

if (pageInfo) {
  const menuItems = document.querySelectorAll('[js-menu-item]');
  const infoSections = document.querySelectorAll('[js-info-section]');
  const infoMobileSections = document.querySelectorAll('[js-info-mobile]');

  menuItems.forEach((item) => {
    item.addEventListener('click', () => {

      menuItems.forEach((menu) => {
        menu.classList.remove('active');
      });

      infoSections.forEach(function(section) {
        section.classList.remove('active');
      });

      infoSections.forEach(function(section) {
        if (item.id === section.id) {
          item.classList.add('active');
          section.classList.add('active');
        }
      })
    });
  });

  menuItems.forEach((menuItem, index) => {
    menuItem.setAttribute('id-menu', index);

    menuItem.addEventListener('click', () => {

      let numOfMenu = menuItem.getAttribute('id-menu');

      infoMobileSections.forEach((infoMobileSection, index) => {
        infoMobileSection.setAttribute('id-section', index);

        if (infoMobileSection.getAttribute('id-section') === numOfMenu && infoMobileSection.classList.contains('active') && menuItem.classList.contains('active')) {
          menuItem.classList.remove('active');
        }

        if (infoMobileSection.getAttribute('id-section') === numOfMenu && !infoMobileSection.classList.contains('active')) {
          infoMobileSection.classList.toggle('active');
        } else {
          infoMobileSection.classList.remove('active');
        }
      })
    })
  })


  const faqTitles = document.querySelectorAll('[js-title-question]');
  const faqBtns = document.querySelectorAll('[js-btn-question]');
  const faqAnswers = document.querySelectorAll('[js-answer-question]');

  faqBtns.forEach((btn, index) => {
    btn.setAttribute('id-btn', index);
  })

  faqTitles.forEach((title, index) => {
    title.setAttribute('id-title', index);

    title.addEventListener('click', () => {
      let numberOfTitle = title.getAttribute('id-title');

      faqAnswers.forEach((answer, index) => {
        answer.setAttribute('id-answer', index);

        if (answer.getAttribute('id-answer') === numberOfTitle && !answer.classList.contains('active')) {
          answer.classList.toggle('active');
        } else {
          answer.classList.remove('active');
        }
      })

      faqBtns.forEach((btn) => {
        if (btn.getAttribute('id-btn') === numberOfTitle && !btn.classList.contains('active')) {
          btn.classList.toggle('active');
        } else {
          btn.classList.remove('active');
        }
      })
    })
  })

}


