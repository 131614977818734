import Swiper, { Mousewheel } from 'swiper';

const productSliderEl = document.querySelector('[data-product-slider]');
if (productSliderEl) {
  const productSliderInit = () => {
    const productSlider = new Swiper(productSliderEl, {
      modules: [Mousewheel],
      mousewheel: true,
      slidesPerView: 2,
      spaceBetween: 20,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1060: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    });

    window.innerWidth > 768 ? productSlider.init() : productSlider.destroy();
  }

  productSliderInit();

  window.addEventListener("resize", productSliderInit);
}
